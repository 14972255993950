import React, { ReactNode } from 'react'
import { TrackedButton } from '@flock/shared-ui'
import { Grid, Typography, useMediaQuery, Box } from '@mui/material'
import theme from '@flock/shared-ui/src/theme/theme'
import Fade from 'react-reveal/Fade'
import How721WorksStep1 from '../../images/what-is-721-step-1.webp'
import How721WorksStep2 from '../../images/what-is-721-step-2.webp'
import How721WorksStep3 from '../../images/what-is-721-step-3.webp'
import SectionLayout from '../SharedComponents/SectionLayout'
import { ABOUT_US_PATH, FAQ_PATH, ONBOARDING_PATH } from '../../routeConstants'

type How721WorksContentSectionProps = {
  firstRow?: {
    title: ReactNode
    description: ReactNode
    buttonText: string
    buttonOnClickPath: string
  }
  secondRow?: {
    title: string
    description: ReactNode
    buttonText: string
    buttonOnClickPath: string
  }
  thirdRow?: {
    title: string
    description: ReactNode
    buttonText: string
    buttonOnClickPath: string
  }
}

const How721WorksContentSection = (props: How721WorksContentSectionProps) => {
  const { firstRow, secondRow, thirdRow } = props
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <SectionLayout
        name="How-721-Works-Section-Row-1"
        backgroundColor="gray1.main"
        sx={{
          zIndex: 3,
        }}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Fade duration={500} distance="5rem" left>
            <Box pb="16px" display="flex" justifyContent="center">
              <Box
                component="img"
                src={How721WorksStep1}
                alt="Overmoon App Preview"
                sx={{
                  width: {
                    xs: '266px',
                    sm: '416px',
                    md: '544px',
                  },
                  height: {
                    xs: '219px',
                    sm: '337px',
                    md: '440px',
                  },
                }}
              />
            </Box>
          </Fade>
        </Grid>
        {!isTablet ? <Grid item md={1} /> : null}
        <Grid item xs={12} sm={12} md={5} display="flex" alignItems="center">
          <Fade duration={500} distance="5rem" right>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ xs: '16px', sm: '32px' }}
            >
              <Typography
                variant="h2"
                textAlign="left"
                color="midnightBlue.main"
              >
                {firstRow?.title}
              </Typography>
              <Typography variant="p1m" textAlign="left" color="gray8.main">
                {firstRow?.description}
              </Typography>
            </Box>
          </Fade>
        </Grid>
      </SectionLayout>
      <SectionLayout
        name="How-721-Works-Section-Row-2"
        backgroundColor="gray1.main"
      >
        <Grid item xs={12} sm={12} md={6} order={{ sm: 1, xs: 1, md: 3 }}>
          <Fade duration={500} distance="5rem" right>
            <Box pb="16px" display="flex" justifyContent="center">
              <Box
                component="img"
                src={How721WorksStep2}
                sx={{
                  borderRadius: '16px',

                  width: {
                    xs: '266px',
                    sm: '416px',
                    md: '544px',
                  },
                  height: {
                    xs: '219px',
                    sm: '337px',
                    md: '456px',
                  },
                }}
              />
            </Box>
          </Fade>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          order={{ sm: 2, xs: 2, md: 1 }}
          display="flex"
          alignItems="center"
        >
          <Fade duration={500} distance="5rem" left>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ xs: '16px', sm: '32px' }}
            >
              <Typography
                variant="h2"
                textAlign="left"
                color="midnightBlue.main"
              >
                {secondRow?.title}
              </Typography>
              <Typography variant="p1m" textAlign="left" color="gray8.main">
                {secondRow?.description}
              </Typography>
              <Grid md={6} sm={7} xs={12} item>
                <TrackedButton
                  to={secondRow?.buttonOnClickPath}
                  variant="secondary"
                  size="small"
                  sx={{
                    display: 'block',
                    whiteSpace: 'nowrap',
                    width: {
                      sm: 'fit-content!important',
                    },
                  }}
                >
                  {secondRow?.buttonText}
                </TrackedButton>
              </Grid>
            </Box>
          </Fade>
        </Grid>
        {!isTablet ? <Grid item md={1} order={{ md: 2 }} /> : null}
      </SectionLayout>
      <SectionLayout
        name="How-721-Works-Section-Row-3"
        backgroundColor="gray1.main"
      >
        <Grid item xs={12} sm={12} md={6}>
          <Fade duration={500} distance="5rem" left>
            <Box pb="16px" display="flex" justifyContent="center">
              <img
                src={How721WorksStep3}
                alt="Overmoon App Preview"
                style={{
                  width: (() => {
                    if (isMobile) return '266px'
                    if (isTablet) return '416px'
                    return '544px'
                  })(),
                  height: (() => {
                    if (isMobile) return '152px'
                    if (isTablet) return '237px'
                    return '310px'
                  })(),
                }}
              />
            </Box>
          </Fade>
        </Grid>
        {!isTablet ? <Grid item md={1} /> : null}
        <Grid item xs={12} sm={12} md={5} display="flex" alignItems="center">
          <Fade duration={500} distance="5rem" right>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap={{ xs: '16px', sm: '32px' }}
            >
              <Typography
                variant="h2"
                textAlign="left"
                color="midnightBlue.main"
              >
                {thirdRow?.title}
              </Typography>
              <Typography variant="p1m" textAlign="left" color="gray8.main">
                {thirdRow?.description}
              </Typography>
              <Grid md={6} sm={7} xs={12} item>
                <TrackedButton
                  to={thirdRow?.buttonOnClickPath}
                  variant="primary"
                  size="small"
                  sx={{
                    display: 'block',
                  }}
                >
                  {thirdRow?.buttonText}
                </TrackedButton>
              </Grid>
            </Box>
          </Fade>
        </Grid>
      </SectionLayout>
    </>
  )
}

const ThirdRowDescription = () => (
  <>
    With Overmoon, we&apos;ve designed a product that allows you to make your
    transition to passive ownership...
    <br />
    <br />
    Exchange your property for shares once and receive passive income forever!
  </>
)

export default How721WorksContentSection

How721WorksContentSection.defaultProps = {
  firstRow: {
    title: 'Diversify your ownership',
    description: `When you invest in the Overmoon 721 Fund, your property is added to our portfolio of homes across the country. These assets vary in terms of price point, yield, and appreciation potential, creating a diversified investment that decreases the risk of owning one individual house.`,
    buttonText: 'About Overmoon',
    buttonOnClickPath: ABOUT_US_PATH,
  },
  secondRow: {
    title: 'You own shares in a portfolio of rentals',
    description:
      'The Overmoon 721 Fund brings institutional-caliber expertise to individual owners like you. It’s hard to generate outsized returns if owning a rental property is your part-time job, but our team of real estate professionals provide industry expertise and economies of scale for your benefit. Instead of stressing about the costs of ownership, you just sit back, relax, and collect, while we handle all of the work for you.',
    buttonText: 'Common Questions',
    buttonOnClickPath: FAQ_PATH,
  },
  thirdRow: {
    title: 'Your ownership experience',
    description: <ThirdRowDescription />,
    buttonText: 'Get started',
    buttonOnClickPath: ONBOARDING_PATH,
  },
}
