import React from 'react'
import Hero from '../components/SharedComponents/Hero/Hero'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import WhatIs721ContentSection from '../components/How721WorksComponents/How721WorksContentSection'

import WhatIs721Hero from '../images/what-is-721-hero.webp'

const pageTitle = 'Overmoon | How 721 works'
const pageDescription = `Keep the benefits of owning a rental property and leave all of the responsibilities to us.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const How721Works = () => (
  <PageWrapper title={pageTitle} trackingName="what-is-overmoon">
    <Hero
      title="Easier than owning. Better than selling."
      description="Keep the benefits of owning a rental property and leave all of the responsibilities to us."
      imgSrc={WhatIs721Hero}
      caption="Miramar Beach, FL"
    />
    <WhatIs721ContentSection />
  </PageWrapper>
)

export default How721Works
